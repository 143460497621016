import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useRouterStore } from '~/components/hooks/RouterStore'

const IndexPage = () => {
  const router = useRouter()
  const routerStore = useRouterStore()
  useEffect(() => {
    router
      .replace({
        pathname:
          routerStore.lastPath && routerStore.validPaths.includes(routerStore.lastPath)
            ? routerStore.lastPath
            : 'overview',
      })
      .catch(e => console.error(e))
  })
  return null // render nothing; redirect should show home
}

export default IndexPage
